import axios from "axios";
import router from "@/router";
import { Message } from "element-ui";
// 创建实例

const instance = axios.create({
  timeout: 15000,

  // baseURL: 'https://api.kaitingbao.com/',  //环境
  //baseURL: 'http://43.154.50.66', //li环境
  // baseURL: 'http://47.106.73.132:81'   //li环境
  // baseURL: "http://43.140.195.157:81",
  baseURL: "http://43.140.195.157:81/",
});
// 实现请求拦截器
instance.interceptors.request.use(
  (config) => {
    let token = localStorage.getItem("token");
    if (token) {
      config.headers = config.headers || {};
      config.headers["Authorization"] = "Bearer " + token;
    }
    return config;
  },
  (err) => {
    return Promise.reject(err);
  }
);
// 实现响应拦截器
instance.interceptors.response.use(
  (res) => {
    let token = localStorage.getItem("token");
    let data = JSON.parse(localStorage.getItem("TokenData"));
    if (token) {
      if (res.data.Data == "Token已失效" || data.Token !== token) {
        Message({
          type: "error",
          message: "登录已失效，请重新登录",
        });
        localStorage.clear();
        router.push("/");
      }
    } else {
      router.push("/");
    }

    return res.data;
  },
  (err) => {
    return Promise.reject(err);
  }
);

export default instance;
