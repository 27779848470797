import axios from "./request";
// 用户注册
// export const RegisterFn = (params) => axios.post(`/api/Auth/Register`, {}, {params})

//发送验证码
// /api/Auth/SendRegCode
export const SendRegCodeFn = (params) =>
  axios.post(`/api/Auth/SendRegCode`, {}, { params });

//  用户登录
export const getLoginFn = (params) =>
  axios.post(`/api/Auth/Login`, {}, { params });

// 获取用户信息
// /api/Auth/api/GetUserInfo
// export const GetUserInfo = () => axios.get(`/api/Auth/GetUserInfo`)

//获取所有商品
// ​/api/Store/GetAllStore
// export const GetAllStore = (params) => axios.post(`/api/Store/GetAllStore`, {}, {params})

// 购买
// /api/M_Member/AddOrderFree
export const AddOrderFree = (params) =>
  axios.post(`/api/Store/GetBuyCode`, {}, { params });

//获取自己的充值记录
//  /api/Auth/GetMyCards
export const GetMyCards = () => axios.get("/api/Auth/GetMyCards");

//获得所有用户
// /api/Auth/GetAllUser
// export const GetAllUser = (params) => axios.get('/api/Auth/GetAllUserByLimitPage', {params})

//修改用户点数
// /api/Auth/AddNum
export const SelectUserPoint = (params) =>
  axios.get("/api/Auth/AddNum", { params });

//获得所有充值记录
//
// export const GetAllRechargeHistory = (params) => axios.post('/api/Store/GetAllStoreHisLimit', {}, {params})

//获得自己的使用记录
//
export const GetMySelfUseHistory = () => axios.get("/ocr/GetUseAllHistory");

//添加商品
//
export const AddStoreAndUpdate = (params) =>
  axios.post("/api/Store/AddOrUpdateStore", params);

//删除商品
///api/Store/DeleteStore
// export const DeleteStore = (params) => axios.post('/api/Store/DeleteStore', {}, {params})

//获取公告
// /api/Auth/GetNote
export const getNote = () => axios.get("/api/Auth/GetNote");

//刷新token
// /api/Auth/UpdateCode
export const UpdateCode = () => axios.get("/api/Auth/UpdateCode");

//刷新token vnc
// /api/Auth/GetVNCToken
export const GetVNCToken = () => axios.get("/api/Auth/GetVNCToken");

//设置公告
// /api/Auth/SetNote
export const SetNote = (params) => axios.get("/api/Auth/SetNote", { params });

//删除公告
// /api/Auth/DelNote
export const DelNote = (params) => axios.get("/api/Auth/DelNote", { params });

//修改密码
// /api/Auth/UpdatePassWord
export const UpdatePassword = (params) =>
  axios.get("/api/Auth/UpdatePassWord", { params });

//获取当前可用服务器
// /api/Auth/GetAllServiceIP
export const GetAllServiceIP = () => axios.get("/api/Auth/GetAllServiceIP");

//添加服务器
// /api/Auth/AddServiceIP
export const AddServiceIP = (params) =>
  axios.get("/api/Auth/AddServiceIP", { params });

//获取例子
// /api/Auth/GetDemo
export const GetDemo = (params) => axios.get("/api/Auth/GetDemo", { params });

//设置例子
// /api/Auth/GetDemo
export const SetDemo = (params) => axios.post("/api/Auth/SetDemo", params);

// 删除例子
// /api/Auth/DelDemo
export const DelDemo = (params) =>
  axios.post("/api/Auth/DelDemo", {}, { params });

//根据邮箱查询用户
///api/Auth/FindByUserName
export const FindUser = (params) =>
  axios.get("/api/Auth/FindByUserName", { params });

//删除服务器
///api/Auth/DelServiceIP
export const DelServiceIP = (params) =>
  axios.get("/api/Auth/DelServiceIP", { params });

//删除用户
///api/Auth/DeleteUser
export const DeletePerson = (params) =>
  axios.get("/api/Auth/DeleteUser", { params });

// 设置服务器部署链接
// /api/Auth/SetUrl
export const SetUrl = (params) =>
  axios.post("/api/Auth/SetUrl", {}, { params });

// 设置代理购买价格
// /api/Auth/SetPrice
export const SetPrice = (params) =>
  axios.post("/api/Auth/SetPrice", {}, { params });

// 获取服务器部署链接
// GetUrl
export const GetUrl = () => axios.get("/api/Auth/GetUrl");

// 获取Vnc 代理商购买天数
export const GetVNCNetWorkLinks = () =>
  axios.get("/api/Auth/GetVNCNetWorkLinks");
// 获取Vnc 下载按钮链接
export const GetVNCAppDwonUrl = () => axios.get("/api/Auth/GetVNCAppDwonUrl");

// 添加代理服务器
// AddTrans
export const AddTrans = (params) => axios.post("/api/Auth/AddTrans", params);

// 购买代理
// /api/Store/GetBuyAgentCode
export const GetBuyAgentCode = (params) =>
  axios.post("/api/Store/GetBuyAgentCode", {}, { params });

// 获取所有代理服务器
// /api/Auth/GetAllTrans
export const GetAllTrans = () => axios.post("/api/Auth/GetAllTrans");

// 修改代理服务器
// /api/Auth/UpdateTrans
export const UpdateTrans = (params) =>
  axios.post("/api/Auth/UpdateTrans", {}, { params });

// 当前价格
// /api/Auth/GetPrice
export const GetPrice = () => axios.get("/api/Auth/GetPrice");

// 修改代理天数
// /api/Auth/AddAgentDay
export const AddAgentDay = (params) =>
  axios.get("/api/Auth/AddAgentDay", { params });

// 设置VNC购买跳转链接
// /api/Auth/SetVNCNetworkLinks
export const SetVNCNetworkLinks = (params) =>
  axios.get("/api/Auth/SetVNCNetworkLinks", { params });
// 设置VNC购买跳转链接
// /api/Auth/SetVNCAppDwonUrl
export const SetVNCAppDwonUrl = (params) =>
  axios.get("/api/Auth/SetVNCAppDwonUrl", { params });

// 删除代理
//
// /api/Auth/DelTrans
export const DelTrans = (params) =>
  axios.post("/api/Auth/DelTrans", {}, { params });

// 获取发卡网
// /api/Auth/GetNetworkLinks
export const GetNetworkLinks = () => axios.get("/api/Auth/GetNetworkLinks");

// 设置发卡网
// /api/Auth/SetNetworkLinks
export const SetNetworkLinks = (params) =>
  axios.get("/api/Auth/SetNetworkLinks", { params });

// 生成卡密
// /api/Auth/GenerateCamilo
export const GenerateCamilo = (params) =>
  axios.get("/api/Auth/GenerateCamilo", { params });

// 使用卡密充值
// /api/Auth/useCardSecret
export const useCardSecret = (params) =>
  axios.get("/api/Auth/useCardSecret", { params });

// 获取所有卡密
// /api/Auth/GetAllCards
export const GetAllCards = () => axios.get("/api/Auth/GetAllCards");

// 修改公告
// /api/Auth/UPNote
export const UpNote = (params) => axios.get("/api/Auth/UPNote", { params });

// 删除卡密
// /api/Auth/DeleteCARDSClose
export const DeleteCARDSClose = (params) =>
  axios.get("/api/Auth/DeleteCARDSClose", { params });

// 本地部署
// /api/Auth/GetlocalDeployment
export const GetlocalDeployment = () =>
  axios.get("/api/Auth/GetlocalDeployment");

// 设置本地部署
// /api/Auth/SetlocalDeployment
export const SetlocalDeployment = (params) =>
  axios.get("/api/Auth/SetlocalDeployment", { params });

// 代理商测试
// /api/Auth/GettheAgentTestLink
export const GettheAgentTestLink = () =>
  axios.get("/api/Auth/GettheAgentTestLink");

// 设置代理商测试
// /api/Auth/SettheAgentTestLink
export const SettheAgentTestLink = (params) =>
  axios.get("/api/Auth/SettheAgentTestLink", { params });

// 管理员添加用户
export const RegisterUser = (params) =>
  axios.post("/api/Auth/RegisterUser", {}, { params });

// 设置网络验证版本和验证链接
export const SetVersion = (params) =>
  axios.post("/api/Auth/SetVersion", {}, { params });

// 数据展示
export const getInfo = (params) => axios.get("/api/Auth/GetInfos");

// 数据展示
export const addGPUDay = (params) =>
  axios.get("/api/Auth/AddGPUDay", { params });

export const addWebDay = (params) =>
  axios.get("/api/Auth/AddWebDay", { params });

export const getWebNetworkLinks = () =>
  axios.get("/api/Auth/GetWebNetworkLinks");

export const setWebNetworkLinks = (params) =>
  axios.get("/api/Auth/SetWebNetworkLinks", { params });

export const getSDDSNetworkLinks = () =>
  axios.get("/api/Auth/GetSDDSNetworkLinks");

export const setWebSDASDNetworkLinks = (params) =>
  axios.get("/api/Auth/SetWebSDASDNetworkLinks", { params });

export const getdawdwazxNetworkLinks = () =>
  axios.get("/api/Auth/GetdawdwazxNetworkLinks");

export const setWebdajiwNetworkLinks = (params) =>
  axios.get("/api/Auth/SetWebdajiwNetworkLinks", { params });

export const getgkyukyuNetworkLinks = () =>
  axios.get("/api/Auth/GetgkyukyuNetworkLinks");

export const setadadaxzbnNetworkLinks = (params) =>
  axios.get("/api/Auth/SetadadaxzbnNetworkLinks", { params });

//app识别展示
export const GetAllCardUseLimitPage = (params) =>
  axios.get("/api/CardUse/GetAllCardUseLimitPage", { params });
export const GetAllCardCount = (params) =>
  axios.get("/api/CardUse/GetAllCardCount", { params });
//清空已到期
export const ClearCardHavaPush = (params) =>
  axios.get("/api/CardUse/ClearCardHavaPush", { params });

//获取链接
// export const GetlocalDeployment = (params) => axios.get('/api/Auth/GetUrl', {params});
export const GetAppTeach = (params) =>
  axios.get("/api/Auth/GetAppTeach", { params });
export const GetAppbuy = (params) =>
  axios.get("/api/Auth/GetAppbuy", { params });
export const GetMoney = (params) => axios.get("/api/Auth/GetMoney", { params });
export const GetAppDwonUrl = (params) =>
  axios.get("/api/Auth/GetAppDwonUrl", { params });

// 获取网络验证版本和验证链接
export const GetVersionAndUrl = (params) =>
  axios.get("/api/Auth/GetVersionAndUrl", { params });

//设置链接
export const SetAPPTeach = (params) =>
  axios.get("/api/Auth/SetAPPTeach", { params });
export const SetAPPbuy = (params) =>
  axios.get("/api/Auth/SetAPPbuy", { params });
export const SetMoney = (params) => axios.get("/api/Auth/SetMoney", { params });
export const SetAppDwonUrl = (params) =>
  axios.get("/api/Auth/SetAppDwonUrl", { params });

// 添加vnc时间
export const AddVNCDay = (params) =>
  axios.get("/api/Auth/AddVNCDay", { params });

//教案
//  用户登录
export const Login = (params) => axios.post(`/api/Auth/Login`, {}, { params });
//  用户登录
export const GetUserInfo = (params) =>
  axios.get(`/api/Auth/GetUserInfo`, { params });

// 教师管理
//  管理员新增教师
export const Register = (params) => axios.post(`/api/Auth/Register`, params);
//  管理员修改教师
export const UpdateTeacher = (params) =>
  axios.post(`/api/Auth/UpdateTeacher`, params);
//  获取教室列表
export const GetAllUser = (params) =>
  axios.get(`/api/Auth/GetAllUser`, { params });
export const GetAllUserName = (params) =>
  axios.get(`/api/Auth/GetAllUserName`, { params });
//  通过id删除教师
export const DeleteTeacher = (params) =>
  axios.get(`/api/Auth/DeleteTeacher`, { params });
//  教师修改自己密码
export const ResertPassWord = (params) =>
  axios.post(`/api/Auth/ResertPassWord`, {}, { params });
//  管理员修改教师密码
export const UpdateTeacherPassWord = (params) =>
  axios.post(`/api/Auth/UpdateTeacherPassWord`, {}, { params });

//  门店管理
// 所有门店
export const GetAllStore = (params) =>
  axios.get(`/api/Store/GetAllStore`, {}, { params });
//  管理员创建门店
export const AddStore = (params) => axios.post(`/api/Store/AddStore`, params);
//  管理员修改门店
export const UpdateStore = (params) =>
  axios.post(`/api/Store/UpdateStore`, params);
// 所有门店
export const DeleteStore = (params) =>
  axios.get(`/api/Store/DeleteStore`, { params });
// 获取自己门店课室
export const GetOwnStore = (params) =>
  axios.get(`/api/Store/GetOwnStore`, { params });

//  课程管理
// 分页获取所有课程
export const GetAllWorkLimitPage = (params) =>
  axios.get(`/api/Work/GetAllWorkLimitPage`, { params });
// 获取所有课程
export const GetAllWork = (params) =>
  axios.get(`/api/Work/GetAllWork`, {}, { params });
//根据条件获取教案
// export const GetRealTeachPlanBySome = (params) => axios.get(`/api/TeachPlan/GetRealTeachPlanBySome`, {params});
// 添加课程
export const AddWork = (params) => axios.post(`/api/Work/AddWork`, params);
// 修改课程
export const UpdateWork = (params) =>
  axios.post(`/api/Work/UpdateWork`, params);
// 修改课程
export const DeleteWork = (params) =>
  axios.get(`/api/Work/DeleteWork`, { params });

// 教师获取自己的课程课程
export const GetAllWorksForTeacher = (params) =>
  axios.get(`/api/Work/GetAllWorksForTeacher`, {}, { params });
//教师获取课程列表
export const GetAllClassByTeacher = (params) =>
  axios.get(`/api/Class/GetAllClassByTeacher`, {}, { params });

// 课程审批
//教师申请添加课程
export const AddExamine = (params) =>
  axios.post(`/api/Examine/AddExamine`, params);
//获取自己一周要上的课程
export const GetAllClassMeByTime = (params) =>
  axios.get(`/api/Class/GetAllClassMeByTime`, { params });
//获取这一周课程
export const GetAllClassByTime = (params) =>
  axios.get(`/api/Class/GetAllClassByTime`, { params });
export const GetAllClassByStartTime = (params) =>
  axios.get(`/api/Class/GetAllClassByStartTime`, { params });
//分页获取所有审批信息
export const GetAllExamineLimitPage = (params) =>
  axios.get(`/api/Examine/GetAllExamineLimitPage`, { params });
//教师分页获取所有审批信息
export const GetOneExamineLimitPage = (params) =>
  axios.get(`/api/Examine/GetOneExamineLimitPage`, { params });

//审批
export const UpdateExamine = (params) =>
  axios.post(`/api/Examine/UpdateExamine`, {}, { params });

// 排课管理
export const GetAllClassLimitPage = (params) =>
  axios.get(`/api/Class/GetAllClassLimitPage`, { params });
// 修改课程学生人数
export const UpdateClassNum = (params) =>
  axios.post(`/api/Class/UpdateClassNum`, {}, { params });
// 课程统计
export const ClassStatistics = (params) =>
  axios.get(`/api/Class/ClassStatistics`, { params });

// 获取某个门店信息
export const GetOneStore = (params) =>
  axios.get(`/api/Store/GetOneStore`, { params });

//  教案管理
// 分页获取所有教案
// export const GetAllTeachPlanLimitPage = (params) => axios.get(`/api/TeachPlan/GetAllTeachPlanLimitPage`, {params});
export const GetAllTeachPlan = (params) =>
  axios.get(`/api/TeachPlan/GetAllTeachPlan`, {}, { params });
//  上传大教案
export const AddTeachPlan = (params) =>
  axios.post(`/api/TeachPlan/AddTeachPlan`, params);
//  修改教案年月周
export const UpdateTeachPlan = (params, body) =>
  axios.post(`/api/TeachPlan/UpdateTeachPlan`, body, { params });
// 通过id删除教案
export const DeleteTeachPlan = (params) =>
  axios.get(`/api/TeachPlan/DeleteTeachPlan`, { params });
//  上传小教案
// export const UpLoadTeachPlan = (params,body) => axios.post(`/api/TeachPlan/UpLoadTeachPlan`, body,{params});
export const AddPlanDetail = (params) =>
  axios.post(`/api/PlanDetail/AddPlanDetail`, params);
// 编辑小教案
export const UpdatePlanDetail = (params) =>
  axios.post(`/api/PlanDetail/UpdatePlanDetail`, params);
// 编辑小教案
export const UpdatePlanDetailInfo = (params, body) =>
  axios.post(`/api/PlanDetail/UpdatePlanDetailInfo`, body, { params });
// 通过id删除小教案
export const DeletePlanDetail = (params) =>
  axios.get(`/api/PlanDetail/DeletePlanDetail`, { params });

// 搜索
//搜索教师列表
export const SearchTeacher = (params) =>
  axios.get(`/api/Auth/SearchTeacher`, { params });
//搜索审批记录
export const SearchAllExamineLimitPage = (params) =>
  axios.get(`/api/Examine/SearchAllExamineLimitPage`, { params });
//店长搜索审批记录
export const SearchOwnExamineLimitPage = (params) =>
  axios.get(`/api/Examine/SearchOwnExamineLimitPage`, { params });
// 搜索所有课程 后台
export const SearchWorkLimitPage = (params) =>
  axios.get(`/api/Work/SearchWorkLimitPage`, { params });
// 搜索所有课程 排课 后台
export const SearchAllClassLimitPage = (params) =>
  axios.get(`/api/Class/SearchAllClassLimitPage`, { params });
// 搜索所有课程 前台
export const TeacherSearchAllClassLimitPage = (params) =>
  axios.get(`/api/Class/TeacherSearchAllClassLimitPage`, { params });

// 搜索所有课程 前台
export const GetAllPlanDetail = (params) =>
  axios.get(`/api/PlanDetail/GetAllPlanDetail`, { params });

// 根据条件获取小教案
export const GetRealTeachPlanBySome = (params) =>
  axios.get(`/api/PlanDetail/GetRealTeachPlanBySome`, { params });

// 根据id获取教案分类详情
export const GetAllTeachPlanByOne = (params) =>
  axios.get(`/api/TeachPlan/GetAllTeachPlanByOne`, { params });

// 条件统计 小
// export const ClassStatistics = (params) => axios.get(`/api/Class/ClassStatistics`, {params});
